.Logo {
  height: 5rem;
  margin: 1rem 2rem;
  align-self: center;
}

.LogoColor {
  filter: brightness(0.4);
}

.Toolbar {
  position: "sticky";
  justify-content: center;
}

.Root {
  flex: 0 1 auto;
}
