body.leaderboard {
  background-image: url(../../../assets/icons/leaderboard/bg-leaderboard.png);
  background-color: #0a2b38;
  height: auto;
}

body.leaderboard header,
body.leaderboard .Header_Root__3QQ3- {
  display: none;
}

body.leaderboard .headerFrameRoot {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

body.leaderboard .headerFrameRoot .logo,
body.leaderboard .headerFrameRoot .heading,
body.leaderboard .headerFrameRoot .trophy {
  width: 33.33%;
}

body.leaderboard .headerFrameRoot .logo img {
  max-width: 100%;
  width: 16rem;
}

body.leaderboard .headerFrameRoot .heading img {
  max-width: 100%;
  width: 40vw;
}

body.leaderboard .headerFrameRoot .trophy img {
  width: 7rem;
}

/* Styeld Table header and body */
body.leaderboard table {
  border-collapse: separate;
  border-spacing: 11px 12px;
}
body.leaderboard table thead th span {
  position: relative;
  display: block;
  text-transform: uppercase;
  padding: 12px 0px;
  font-size: 1rem;
}

body.leaderboard table thead th span {
  background-image: url(../../../assets/icons/leaderboard/diamond-shape.svg);
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: center center;
}

body.leaderboard table thead th:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 15px;
  z-index: -3;
  top: -1px;
  right: -15px;
  border-bottom: 0.5px solid #bca25f;
}

body.leaderboard table thead th:last-child:before {
  position: inherit;
}

body.leaderboard table thead th:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 15px;
  z-index: -3;
  bottom: -1px;
  right: -15px;
  border-bottom: 0.5px solid #bca25f;
}

body.leaderboard table thead th:last-child:after {
  position: inherit;
}

body.leaderboard table tbody td,
body.leaderboard table tbody th {
  font-weight: 300;
}

body.leaderboard table thead:after {
  content: "-";
  display: block;
  line-height: 7px;
  color: transparent;
}
