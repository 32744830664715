@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,700;1,300;1,400&display=swap);
/* Body font */

/* @font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../src/assets/fonts/source-serif-pro/SourceSerifPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../src/assets/fonts/source-serif-pro/SourceSerifPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../src/assets/fonts/source-serif-pro/SourceSerifPro-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../src/assets/fonts/source-serif-pro/SourceSerifPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../src/assets/fonts/source-serif-pro/SourceSerifPro-Black.ttf") format("truetype");
}

@font-face {
  font-family: "bebas-neue";
  src: url("../src/assets/fonts/BebasNeue\ Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  font-family: 'Source Serif Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: "a-caslon-pro", "bebas-neue", monospace;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.button {
  position: relative;
  display: block;
  background: transparent;
  width: 200px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 15px;
  text-decoration: none;
  text-transform: uppercase;
  margin: 40px auto;
}
.button:before,
.button:after {
  width: 200px;
  left: 0px;
  height: 27px;
  z-index: -1;
}
.button:before {
  position: absolute;
  content: "";
  border-bottom: none;
  transform: perspective(15px) rotateX(5deg);
}
.button:after {
  position: absolute;
  top: 32px;
  content: "";
  border-top: none;
  transform: perspective(15px) rotateX(-5deg);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Layout_Content__2qMcn {
  margin: 16px 0;
  flex: 1 1 auto;
}

.Header_Logo__CehCj {
  height: 5rem;
  margin: 1rem 2rem;
  align-self: center;
}

.Header_LogoColor__2HZfH {
  -webkit-filter: brightness(0.4);
          filter: brightness(0.4);
}

.Header_Toolbar__2iewA {
  position: "sticky";
  justify-content: center;
}

.Header_Root__3QQ3- {
  flex: 0 1 auto;
}

body.leaderboard {
  background-image: url(/static/media/bg-leaderboard.8c729ebb.png);
  background-color: #0a2b38;
  height: auto;
}

body.leaderboard header,
body.leaderboard .Header_Root__3QQ3- {
  display: none;
}

body.leaderboard .headerFrameRoot {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

body.leaderboard .headerFrameRoot .logo,
body.leaderboard .headerFrameRoot .heading,
body.leaderboard .headerFrameRoot .trophy {
  width: 33.33%;
}

body.leaderboard .headerFrameRoot .logo img {
  max-width: 100%;
  width: 16rem;
}

body.leaderboard .headerFrameRoot .heading img {
  max-width: 100%;
  width: 40vw;
}

body.leaderboard .headerFrameRoot .trophy img {
  width: 7rem;
}

/* Styeld Table header and body */
body.leaderboard table {
  border-collapse: separate;
  border-spacing: 11px 12px;
}
body.leaderboard table thead th span {
  position: relative;
  display: block;
  text-transform: uppercase;
  padding: 12px 0px;
  font-size: 1rem;
}

body.leaderboard table thead th span {
  background-image: url(/static/media/diamond-shape.9c2f066a.svg);
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: center center;
}

body.leaderboard table thead th:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 15px;
  z-index: -3;
  top: -1px;
  right: -15px;
  border-bottom: 0.5px solid #bca25f;
}

body.leaderboard table thead th:last-child:before {
  position: inherit;
}

body.leaderboard table thead th:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 15px;
  z-index: -3;
  bottom: -1px;
  right: -15px;
  border-bottom: 0.5px solid #bca25f;
}

body.leaderboard table thead th:last-child:after {
  position: inherit;
}

body.leaderboard table tbody td,
body.leaderboard table tbody th {
  font-weight: 300;
}

body.leaderboard table thead:after {
  content: "-";
  display: block;
  line-height: 7px;
  color: transparent;
}

