/* Body font */

/* @font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../src/assets/fonts/source-serif-pro/SourceSerifPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../src/assets/fonts/source-serif-pro/SourceSerifPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../src/assets/fonts/source-serif-pro/SourceSerifPro-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../src/assets/fonts/source-serif-pro/SourceSerifPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../src/assets/fonts/source-serif-pro/SourceSerifPro-Black.ttf") format("truetype");
}

@font-face {
  font-family: "bebas-neue";
  src: url("../src/assets/fonts/BebasNeue\ Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
} */

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,700;1,300;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Source Serif Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: "a-caslon-pro", "bebas-neue", monospace;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.button {
  position: relative;
  display: block;
  background: transparent;
  width: 200px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 15px;
  text-decoration: none;
  text-transform: uppercase;
  margin: 40px auto;
}
.button:before,
.button:after {
  width: 200px;
  left: 0px;
  height: 27px;
  z-index: -1;
}
.button:before {
  position: absolute;
  content: "";
  border-bottom: none;
  -webkit-transform: perspective(15px) rotateX(5deg);
  -moz-transform: perspective(15px) rotateX(5deg);
  transform: perspective(15px) rotateX(5deg);
}
.button:after {
  position: absolute;
  top: 32px;
  content: "";
  border-top: none;
  -webkit-transform: perspective(15px) rotateX(-5deg);
  -moz-transform: perspective(15px) rotateX(-5deg);
  transform: perspective(15px) rotateX(-5deg);
}
